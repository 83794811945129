.loginReg {
  background: url('./slider.jpg') no-repeat center center;
  background-size: cover;
  margin-top: 95px; /* Add some margin-top to make space for the header */
  padding: 0;
  height: calc(100vh - 95px); /* Adjust height to account for header height */
  display: grid;
  place-items: center; /* Center both horizontally and vertically */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  position: relative;
  z-index: 1; /* Ensure it's below the header */
}

.container {
  width: 100%;
  max-width: 1200px; /* Adjust as per your design */
  padding: 0 20px;
  box-sizing: border-box;
}

.loginReg-row {
  display: flex;
  justify-content: space-between; /* Distribute items evenly */
  gap: 40px; /* Adjust spacing between items */
  width: 100%;
}

.single-loginReg {
  position: relative;
  text-align: left;
  z-index: 3;
  border-radius: 5px;
  background: #1a76d1;
  transition: all 0.3s ease-out;
  width: calc(50% - 10px); /* Adjust width and gap as per your design */
  padding: 20px;
  box-sizing: border-box;
}

.single-loginReg .inner {
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.single-loginReg:before {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 80%;
  height: 90%;
  background: #1a76d1;
  opacity: 0;
  filter: blur(10px);
  transition: all 0.3s ease-out;
}

.single-loginReg:hover:before {
  opacity: 0.8;
}

.single-loginReg:hover {
  transform: translateY(-5px);
}

.single-loginReg .single-content {
  color: #fff;
}

.single-loginReg h4 {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 10px;
}

.single-loginReg p {
  margin-top: 10px;
}

.single-loginReg a {
  color: #fff;
  font-weight: 500;
  display: inline-block;
  position: relative;
}

.single-loginReg a:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 0%;
  background: #fff;
  transition: all 0.4s ease;
}

.single-loginReg a:hover:before {
  width: 100%;
}


/*=============================
End LoginReg CSS
===============================*/


/*=============================
Start Header CSS
===============================*/

/* Header.css */
.header {
  width: 100%;
  background-color:#fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
  position: fixed; /* Fixed at the top of the viewport */
  top: 0;
  left: 0;
  z-index: 1000; /* High z-index to ensure it stays on top */
  display: flex; /* Use Flexbox for layout */
  justify-content: center; /* Center the items horizontally */
  align-items: center; /* Center the items vertically */
  padding: 15px 0;
}

.header .main-menu {
  display: flex; /* Use Flexbox for layout */
  justify-content: center; /* Center the navigation items */
}

.header .nav {
  display: flex; /* Use Flexbox for layout */
  list-style: none;
  padding: 0;
  margin: 0;
}

.header .nav li {
  margin: 0 15px;
}

.header .nav li a {
  color: #2C2D3F;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding: 25px 12px;
}

.header .nav li a:hover {
  color: #1A76D1;
}

/*=============================
Start Login and Register CSS
===============================*/

/* General container centering */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  margin-top: 100px;
}


/* Login page */
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  background: url('./slider3.jpg') no-repeat center center;
  background-size: cover;
  height: calc(100vh - 80px);
  height: 100vh;
  margin-top: 80px;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Adjust the opacity by changing the last value (0.5) */
  z-index: 1; /* Ensure the overlay is on top of the background but below the content */
}

.login .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 2; /* Ensure the inner content is on top of the overlay */
}

.login .login-form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 30px 50px;
  background-color: rgba(255, 255, 255, 0.9); /* Optional: add a semi-transparent background */
  border-radius: 5px;
  box-shadow: 0px 0px 10px #00000024;
}

.login .login-form h2 {
  position: relative;
  font-size: 32px;
  color: #333;
  font-weight: 600;
  line-height: 27px;
  text-transform: capitalize;
  margin-bottom: 12px;
  padding-bottom: 20px;
  text-align: left;
}

.login .login-form h2:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 2px;
  width: 50px;
  background: #1A76D1;
}

.login .login-form p {
  font-size: 14px;
  color: #333;
  font-weight: 400;
  text-align: left;
  margin-bottom: 50px;
}

.login .form .form-group input {
  width: 100%;
  height: 50px;
  border: 1px solid #eee;
  text-transform: none;
  padding: 0px 15px;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.login .form .btn {
  display: inline-block;
  margin-right: 10px;
  color: #fff;
  line-height: 20px;
  width: 100%;
  background: #1A76D1;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.login .form .btn:hover {
  background: #2C2D3F;
}

.login .login-form .checkbox {
  text-align: left;
  margin: 0;
  margin-top: 20px;
  display: inline-block;
}

.login .login-form .checkbox label {
  font-size: 14px;
  font-weight: 400;
  color: #333;
  position: relative;
  padding-left: 20px;
}

.login .login-form .checkbox label:hover {
  cursor: pointer;
}

.login .login-form .checkbox label input {
  display: none;
}

.login .login-form .checkbox label::before {
  position: absolute;
  content: "";
  left: 0;
  top: 5px;
  width: 15px;
  height: 15px;
  border: 1px solid #1A76D1;
  border-radius: 100%;
}

.login .login-form .checkbox label::after {
  position: relative;
  content: "";
  width: 7px;
  height: 7px;
  left: -16px;
  top: -15px;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  transition: all 0.4s ease;
  display: block;
  font-size: 9px;
  background: #1A76D1;
  border-radius: 100%;
}

.login .login-form .checkbox label.checked::after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.login .login-form .lost-pass {
  display: inline-block;
  margin-left: 25px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
}

.login .login-form .lost-pass:hover {
  color: #1A76D1;
}


/* Register page */
.register {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 95px);
  margin-top: 80px;
  background-color: transparent; /* Fallback background color */
  background-image: url('./slider3.jpg'); /* Specify background image */
  background-size: cover;
  background-position: center;
  padding: 50px 20px;
  position: relative;
  z-index: 1; /* Ensure it's below the header */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Adjust the opacity by changing the last value (0.5) */
  z-index: 1; /* Ensure the overlay is on top of the background but below the content */
}

.register .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.register .register-form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 50px 60px;
  background-color: #fff; /* Optional: add a semi-transparent background */
  border-radius: 5px;
  box-shadow: 0px 0px 10px #00000024;
  position: relative;
  z-index: 2;
}

.register .register-form h2 {
  position: relative;
  font-size: 32px;
  color: #333;
  font-weight: 600;
  line-height: 27px;
  text-transform: capitalize;
  margin-bottom: 12px;
  padding-bottom: 20px;
  text-align: left;
}

.register .register-form h2:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 2px;
  width: 50px;
  background: #1A76D1;
}

.register .register-form p {
  font-size: 14px;
  color: #333;
  font-weight: 400;
  text-align: left;
  margin-bottom: 50px;
}

.register .form .form-group input {
  width: 100%;
  height: 50px;
  border: 1px solid #eee;
  text-transform: none;
  padding: 0px 18px;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.register .form .btn {
  display: inline-block;
  margin-right: 10px;
  color: #fff;
  line-height: 20px;
  width: 100%;
  background: #1A76D1;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.register .form .btn:hover {
  background: #2C2D3F;
}

.register .register-form .checkbox {
  text-align: left;
  margin: 0;
  margin-top: 20px;
  display: inline-block;
}

.register .register-form .checkbox label {
  font-size: 14px;
  font-weight: 400;
  color: #333;
  position: relative;
  padding-left: 20px;
}

.register .register-form .checkbox label:hover {
  cursor: pointer;
}

.register .register-form .checkbox label input {
  display: none;
}

.register .register-form .checkbox label::before {
  position: absolute;
  content: "";
  left: 0;
  top: 5px;
  width: 15px;
  height: 15px;
  border: 1px solid #1A76D1;
  border-radius: 100%;
}

.register .register-form .checkbox label::after {
  position: relative;
  content: "";
  width: 7px;
  height: 7px;
  left: -16px;
  top: -15px;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  transition: all 0.4s ease;
  display: block;
  font-size: 9px;
  background: #1A76D1;
  border-radius: 100%;
}

.register .register-form .checkbox label.checked::after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.register .register-form .terms {
  display: inline-block;
  margin-left: 5px;
  color: #1A76D1;
}

.register .register-form .terms:hover {
  color: #2C2D3F;
}

/*=============================
Start Upload CSS
===============================*/

.document-upload {
  background: url('./slider3.jpg') no-repeat center center;
  background-size: cover;
  margin-top: 95px; /* Add some margin-top to make space for the header */
  padding: 0;
  height: calc(100vh - 95px); /* Adjust height to account for header height */
  display: grid;
  place-items: center; /* Center both horizontally and vertically */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  position: relative;
  z-index: 1; /* Ensure it's below the header */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity by changing the last value (0.5) */
  z-index: 1; /* Ensure the overlay is on top of the background but below the content */
}

.upload-form {
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2; /* Ensure the form is on top of the overlay */
}

.upload-form h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.upload-form .form-group {
  margin-bottom: 20px;
}

.upload-form label {
  font-size: 16px;
  font-weight: 500;
  color: #555;
  margin-right:10px;
}

.upload-form input[type="file"] {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.upload-form button {
  background-color: #1a76d1;
  color: #fff;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

.upload-form button:hover {
  background-color: #2c2d3f;
}

.file-list {
  margin-top: 20px;
}

.file-list h3 {
  font-size: 18px;
  color: #333;
}

.file-list ul {
  list-style-type: none;
  padding: 0;
}

.file-list ul li {
  font-size: 16px;
  color: #555;
}

.success {
  color: #4caf50;
}

/*=============================
Start Verification CSS
===============================*/
.verify {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 95px);
  background-color: transparent; /* Fallback background color */
  background-image: url('./slider3.jpg'); /* Specify background image */
  background-size: cover;
  background-position: center;
  padding: 50px 20px;
  position: relative;
  z-index: 1; /* Ensure it's below the header */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity by changing the last value (0.5) */
  z-index: 1; /* Ensure the overlay is on top of the background but below the content */
}

.verify .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.verify .verify-form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 50px 40px;
  background-color: #fff; /* Optional: add a semi-transparent background */
  border-radius: 5px;
  box-shadow: 0px 0px 10px #00000024;
  position: relative;
  z-index: 2;
}

.verify .verify-form h2 {
  position: relative;
  font-size: 32px;
  color: #333;
  font-weight: 600;
  line-height: 27px;
  text-transform: capitalize;
  margin-bottom: 12px;
  padding-bottom: 20px;
  text-align: left;
}

.verify .verify-form h2:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 2px;
  width: 50px;
  background: #1A76D1;
}

.verify .verify-form p {
  font-size: 14px;
  color: #333;
  font-weight: 400;
  text-align: left;
  margin-bottom: 50px;
}

.verify .form .form-group input {
  width: 100%;
  height: 50px;
  border: 1px solid #eee;
  text-transform: capitalize;
  padding: 0px 10px;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.verify .form .btn {
  display: inline-block;
  margin-right: 10px;
  color: #fff;
  line-height: 20px;
  width: 100%;
  background: #1A76D1;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.verify .form .btn:hover {
  background: #2C2D3F;
}

.verify .verify-form .checkbox {
  text-align: left;
  margin: 0;
  margin-top: 20px;
  display: inline-block;
}

.verify .verify-form .checkbox label {
  font-size: 14px;
  font-weight: 400;
  color: #333;
  position: relative;
  padding-left: 20px;
}

.verify .verify-form .checkbox label:hover {
  cursor: pointer;
}

.verify .verify-form .checkbox label input {
  display: none;
}

.verify .verify-form .checkbox label::before {
  position: absolute;
  content: "";
  left: 0;
  top: 5px;
  width: 15px;
  height: 15px;
  border: 1px solid #1A76D1;
  border-radius: 100%;
}

.verify .verify-form .checkbox label::after {
  position: relative;
  content: "";
  width: 7px;
  height: 7px;
  left: -16px;
  top: -15px;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  transition: all 0.4s ease;
  display: block;
  font-size: 9px;
  background: #1A76D1;
  border-radius: 100%;
}

.verify .verify-form .checkbox label.checked::after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.verify .verify-form .login-pass {
  display: inline-block;
  margin-left: 25px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
}

.verify .verify-form .login-pass:hover {
  color: #1A76D1;
}

/* Error and Success Messages */
.error {
  color: #f44336; /* Red color for error messages */
}

.success {
  color: #4caf50; /* Green color for success messages */
}

/* Container for the admin panel */
.admin-panel {
display: flex;
justify-content: center;
align-items: center;
padding: 50px 0;
background-color: #f9f9f9; /* Fallback background color */
background-image: url('./slider.jpg'); /* Specify verify background image */
background-size: cover;
background-position: center;
width: 100%;
margin-top: 80px;
}

/* Input field styling */
.admin-panel input[type="text"] {
width: 100%;
max-width: 400px;
padding: 10px 15px;
margin: 20px 0;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 16px;
color: #333;
box-sizing: border-box;
}

/* Button styling */
.admin-panel button {
background-color: #1a76d1;
color: #fff;
border: none;
padding: 12px 20px;
cursor: pointer;
border-radius: 4px;
font-size: 16px;
transition: background-color 0.3s ease;
}

/* Button hover effect */
.admin-panel button:hover {
background-color: #2c2d3f;
}

/* Centering form elements */
.admin-panel form {
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}

/* Responsive adjustments */
@media (max-width: 600px) {
.admin-panel input[type="text"],
.admin-panel button {
    width: 100%;
    max-width: none;
}
}

/*=============================
	Start SignOut CSS
===============================*/

.signout {
  background: url('./slider3.jpg') no-repeat center center;
  background-size: cover;
  margin-top: 95px; /* Add some margin-top to make space for the header */
  padding: 0;
  height: calc(100vh - 95px); /* Adjust height to account for header height */
  position: relative;
  z-index: 1; /* Ensure it's below the header */
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Adjust the opacity by changing the last value (0.5) */
  z-index: 1; /* Ensure the overlay is on top of the background but below the content */
}

.signout-form {
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2; /* Ensure the form is on top of the overlay */
}

.signout-left {
  flex: 1;
  background-color: #dc3545; /* Red color for sign-out */
  height: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.form-group .btn {
  background-color: #007bff; /* Blue color for login button */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.signout-btn .btn {
  background-color: #dc3545; /* Red color for sign-out button */
}

.signout-btn .btn:hover {
  background-color: #c82333; /* Darker red on hover */
}

.checkbox {
  margin-bottom: 15px;
}

.lost-pass, .signout-lost-pass {
  margin-top: 10px;
  display: inline-block;
  font-size: 14px;
  color: #007bff; /* Blue color for links */
  text-decoration: none;
}

/*=============================
	Start Download CSS
===============================*/

.download {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 95px);
  background-image: url('./slider3.jpg'); /* Specify background image */
  background-size: cover;
  background-position: center;
  padding: 50px 20px;
  position: relative;
  z-index: 1; /* Ensure it's below the header */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Adjust the opacity by changing the last value (0.5) */
  z-index: 1; /* Ensure the overlay is on top of the background but below the content */
}

.download .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 2; /* Ensure the inner content is on top of the overlay */
}

.download-form {
  width: 100%;
  max-width: 600px;
  background-color:#fff; /* Optional: add a semi-transparent background */
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
}

.download-form h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.download-form .form-group {
  margin-bottom: 20px;
}

.download-form label {
  font-size: 16px;
  font-weight: 500;
  color: #555;
}

.download-form input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.download-form button {
  background-color: #1a76d1;
  color: #fff;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

.download-form button:hover {
  background-color: #2c2d3f;
}

.file-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Add space between items */
}

.file-list span {
  margin-right: 20px; /* Adjust space between filename and download button */
}

.file-list h3 {
  font-size: 18px;
  color: #333;
}

.file-list ul {
  list-style-type: none;
  padding: 0;
}

.file-list ul li {
  font-size: 16px;
  color: #555;
}

.file-list ul li a {
  color: #1a76d1;
  text-decoration: none;
}

.file-list ul li a:hover {
  text-decoration: underline;
}

.success {
  color: #4caf50;
}

.error {
  color: #f44336;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .download-form {
    padding: 20px;
  }

  .download-form h2 {
    font-size: 20px;
  }

  .download-form button {
    padding: 10px 15px;
  }
}

/*=============================
Start Forgot Password CSS
===============================*/

.forgot-password {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 95px);
  background-color: transparent; /* Fallback background color */
  background-image: url('./slider3.jpg'); /* Specify background image */
  background-size: cover;
  background-position: center;
  padding: 50px 20px;
  position: relative;
  z-index: 1; /* Ensure it's below the header */
}

.forgot-password .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 2; /* Ensure the inner content is on top of the overlay */
}

.forgot-password .forgot-password-form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 50px 40px;
  background-color: #fff; /* Optional: add a semi-transparent background */
  border-radius: 5px;
  box-shadow: 0px 0px 10px #00000024;
  position: relative;
  z-index: 2;
}

.forgot-password .forgot-password-form h2 {
  position: relative;
  font-size: 32px;
  color: #333;
  font-weight: 600;
  line-height: 27px;
  text-transform: capitalize;
  margin-bottom: 12px;
  padding-bottom: 20px;
  text-align: left;
}

.forgot-password .forgot-password-form h2:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 2px;
  width: 50px;
  background: #1A76D1;
}

.forgot-password .forgot-password-form p {
  font-size: 14px;
  color: #333;
  font-weight: 400;
  text-align: left;
  margin-top: 20px;
}

.forgot-password .forgot-password-form .form-group {
  margin-bottom: 20px;
}

.forgot-password .forgot-password-form input {
  width: 100%;
  height: 50px;
  border: 1px solid #eee;
  padding: 0 10px;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  box-sizing: border-box;
}

.forgot-password .forgot-password-form .btn {
  display: inline-block;
  width: 100%;
  background: #1A76D1;
  border-radius: 4px;
  padding: 10px 20px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border: none;
  font-size: 14px;
  font-weight: 600;
}

.forgot-password .forgot-password-form .btn:hover {
  background: #2C2D3F;
}

.forgot-password .forgot-password-form .error {
  color: #f44336; /* Red color for error messages */
}

.forgot-password .forgot-password-form .success {
  color: #4caf50; /* Green color for success messages */
}

/* Enhanced Forgot Password Link Styling */
.lost-pass {
  display: inline-block;
  color: #1A76D1; /* Primary color for the link */
  font-size: 14px;
  font-weight: 500;
  text-decoration: none; /* Remove default underline */
  padding: 10px 0; /* Add padding for better spacing */
  transition: color 0.3s, text-decoration 0.3s; /* Smooth transition for hover effects */
}

.lost-pass:hover {
  text-decoration: underline; /* Underline on hover */
  color: #155a8a; /* Darker shade on hover for better visibility */
}

/* Styling for the links */
.links {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align the links */
  margin-top: 20px; /* Add top margin to space them from the form */
}

.link-item {
  color: #1A76D1; /* Primary color for the links */
  font-size: 14px;
  font-weight: 500;
  text-decoration: none; /* Remove default underline */
  margin-bottom: 10px; /* Space between links */
  transition: color 0.3s, text-decoration 0.3s; /* Smooth transition for hover effects */
}

.link-item:hover {
  text-decoration: underline; /* Underline on hover */
  color: #155a8a; /* Darker shade on hover for better visibility */
}

.link-item:last-child {
  margin-bottom: 0; /* Remove bottom margin for the last link */
}
